import axios from "axios";
import { API_TOKEN, API_URL } from '../../.environment'

export const http = axios.create({
    baseURL: API_URL,
    headers: {
        'Authorization': `Bearer ${API_TOKEN}`,
    },
})

export default http