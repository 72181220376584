<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        
        
        <h1 class="text-value-h4 text-center">{{userProfile.company}}</h1>
        

        <v-form @submit.prevent="registerQr">
          <v-text-field
            prepend-icon="mdi-camera"
            label="Código QR"
            type="text"
            v-model="qrCode"
          ></v-text-field>
          <qrcode-stream
            :camera="scannerCamera"
            @init="onInit"
            class="mb-4"
            @decode="onDecode"
            v-if="startScanner"
          >
            <v-btn
              v-if="isMobile"
              fab
              small
              color="primary"
              class="my-1 mx-1"
              @click="switchCamera"
            >
              <v-icon medium>mdi-camera-switch</v-icon>
            </v-btn>
            <div class="text-center py-2">
              <v-progress-circular
                indeterminate
                v-if="loadingScanner"
                color="primary"
              ></v-progress-circular>
            </div>
          </qrcode-stream>
          <!-- Error alert -->
          <v-alert dismissible type="warning" v-if="noRearCamera">
            No se encontró cámara trasera
          </v-alert>
          <v-alert dismissible type="warning" v-if="noFrontCamera">
            No se encontró cámara frontal
          </v-alert>
        </v-form>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Información del visitante</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                readonly
                disabled
                prepend-icon="mdi-account"
                name="name"
                label="Nombres"
                type="text"
                v-model="participant.name"
              ></v-text-field>
              <v-text-field
                readonly
                disabled
                prepend-icon="mdi-account"
                name="lastname"
                label="Apellidos"
                type="text"
                v-model="participant.lastname"
              ></v-text-field>
              <v-text-field
                readonly
                disabled
                prepend-icon="mdi-email"
                name="email"
                label="Correo electrónico"
                type="text"
                v-model="participant.email"
              ></v-text-field>
              <v-text-field
                readonly
                disabled
                prepend-icon="mdi-phone"
                name="phonenumber"
                label="Número de teléfono"
                type="text"
                v-model="participant.phonenumber"
              ></v-text-field>
            </v-form>
          </v-card-text>
        </v-card>
        <v-btn color="primary" class="my-2" block @click="downloadRecords">
          <v-icon>mdi-download</v-icon>
          Descargar registros
        </v-btn>
      </v-flex>
      <v-btn
        color="primary"
        class="my-2"
        fab
        dark
        fixed
        bottom
        right
        @click="scanQR"
      >
        <v-icon>mdi-camera</v-icon>
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { http } from "../plugins/axios";
import { store, mutations } from "../plugins/store";
import { VISITOR_EVENT_ID, STAND_EVENT_ID } from "../../.environment";

export default {
  name: "StandVisitForm",
  components: {
    QrcodeStream,
  },
  data: () => ({
    noRearCamera: false,
    noFrontCamera: false,
    qrCode: "",
    startScanner: false,
    loadingScanner: false,
    participant: {
      name: "",
      lastname: "",
      email: "",
      phonenumber: "",
    },
  }),
  computed: {
    userProfile() {
        return mutations.getUserProfile()
    },
    scannerCamera() {
      if (this.isMobile) return "rear";
      else return "auto";
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async onInit(promise) {
      this.loadingScanner = true;

      try {
        await promise;
      } catch (error) {
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }
      } finally {
        this.loadingScanner = false;
      }
    },
    switchCamera() {
      switch (this.scannerCamera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
      }
    },
    async scanQR() {
      this.startScanner = true;
    },
    downloadRecords() {
      const params = {
        stand_user_id: store.userId,
        event_id: STAND_EVENT_ID,
      };

      http
        .get("stand_visits_export", { params, responseType: "blob" })
        .then((res) => res.data)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Visitas.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((res) => {
          alert(res.response.data.message);
        });
    },
    onDecode(decodedQr) {
      this.qrCode = decodedQr;
      this.registerQr();
      this.startScanner = false;
      // console.log(decodedQr), (this.startScanner = false);
    },
    registerQr() {
      const params = {
        event_id: VISITOR_EVENT_ID,
        stand_user_id: store.userId,
        visitor_user_id: this.qrCode,
      };

      http
        .post("visit_registration", params)
        .then((res) => res.data)
        .then((res) => {
          this.participant = res.data.profile;
          alert(res.message);
        })
        .catch((res) => {
          alert(res.response.data.message);
        });
    },
  },
};
</script>
