import Vue from 'vue'

export const store = Vue.observable({
    userId: '',
    userProfile: '',
})

export const mutations = {
    setUserId(userId) {
        store.userId = userId;
    },
    getUserId() {
        return store.userId;
    },
    setUserProfile(profile) {
        store.userProfile = profile;
    },
    getUserProfile() {
        return store.userProfile;
    }
}