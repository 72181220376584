<template>
  <stand-visit-form />
</template>

<script>
  import StandVisitForm from '../components/StandVisitForm'

  export default {
    name: 'Home',

    components: {
      StandVisitForm,
    },
  }
</script>
